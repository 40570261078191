.MainGameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("/img/war.jpg");
  background-size: cover;

  .InnerGameContainer {
    position: relative;
    width: 1000px;
    height: 600px;
    outline: 5px solid black;
    background: white; } }
