.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .inputText {
      border: 4px solid white;
      color: white;
      width: 300px;
      height: 50px;
      margin: 10px;
      background: none;
      text-align: center;
      outline: none; } }

  .submitButton {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    color: rgba(255,255,255,.6);
    text-shadow: 1px 1px rgba(0,0,0,.3);
    text-decoration: none;
    padding: 20px;
    border-radius: 15px;
    box-shadow: inset 0 0 3px 1px rgba(0,0,0,.8), inset rgba(0,0,0,.3) -5px -5px 8px 5px, inset rgba(255,255,255,.5) 5px 5px 8px 5px, 1px 1px 1px rgba(255,255,255,.1), -2px -2px 5px rgba(0,0,0,.5);
    transition: .2s;
    border: none;
    outline: none;
    margin: 30px;

    &:hover {
      color: rgba(255,255,255,.9); }

    &:active {
      box-shadow: inset 0 0 5px 3px rgba(0,0,0,.8), inset rgba(0,0,0,.3) -5px -5px 8px 5px, inset rgba(255,255,255,.5) 5px 5px 8px 5px, 1px 1px 1px rgba(255,255,255,.2), -2px -2px 5px rgba(0,0,0,.5); } }

  .greenButton {
    background: rgb(10,120,10);

    &:hover {
      background: rgb(20,130,20); }

    &:active {
      background: rgb(0,110,0); } }

  .yellowButton {
    background: rgb(204, 173, 27);

    &:hover {
      background: rgb(222, 191, 27); }

    &:active {
      background: rgb(179, 148, 27); } } }
