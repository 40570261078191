.MainManagerGameContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  .RowContainerSelecting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;

    h1 {
      display: flex;
      flex-direction: row;

      div {
        margin-left: 10px;
        text-decoration: underline; } }

    .Button {
      width: 150px;
      height: 50px;
      margin: 10px; } }

  .RowContainerLinesHP {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 500px; } }
