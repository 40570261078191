.MainMagazineContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: gold;

  .MagazineTableItems {
    color: black;
    border-collapse: collapse;
    border-left: 3px solid #F79361;
    border-right: 3px solid #F79361;
    border-bottom: 3px solid #F79361;
    font-family: "Lucida Grande", sans-serif;
    margin: 15px; }

  .MagazineTableItems caption {
    background: #F79361;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px  4px 0 rgba(0,0,0,.3);
    color: white;
    font-family: "Roboto Slab",serif;
    font-style: normal;
    font-size: 26px;
    text-align: center;
    margin: 0; }

  .MagazineTableItems td, .MagazineTableItems th {
    padding: 10px; }

  .MagazineTableItems th {
    text-align: left;
    font-size: 18px; }

  .MagazineTableItems tr:nth-child(n) {
    background: white; }

  .MagazineTableItems tr:nth-child(2n) {
    background: #E5E5E5; }

  .MagazineTableItems td:last-of-type {
    text-align: center; }

  .MagazineTableItems button {
    display: inline-block;
    padding: 5px 10px;
    background: #F79361;
    box-shadow: 2px 2px 0 0 #a22800;
    position: relative; }

  .MagazineTableItems button:hover {
    box-shadow: none;
    top: 2px;
    left: 2px; } }

.BackButton {
  display: inline-block;
  padding: 5px 10px;
  background: #F79361;
  box-shadow: 2px 2px 0 0 #a22800;
  position: relative; }

.BackButton:hover {
  box-shadow: none;
  top: 2px;
  left: 2px; }
