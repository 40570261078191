.MainNewsContainer {
  position: absolute;
  width: 100%;
  height: 200px;
  top: 0;
  left: 0;
  overflow: hidden;

  .InnerNewsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 200px;
    left: 0;
    bottom: 210px;
    transition: bottom 1s; }

  .view {
    bottom: 0; } }
