.MainLineHPContainer {
  position: relative;
  outline: 5px solid black;
  width: 50px;
  height: 400px;
  margin: 30px;
  overflow: hidden;

  .DynamicContainerLine {
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    background: white;
    transition: height 1s;
    z-index: 1000; }

  .StaticContainerLine {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, greenyellow, Crimson); } }
