.MainComputerContainer {
  position: absolute;
  bottom: 0;
  right: 100px;
  width: 300px;
  height: 400px;
  transition: right 2s;
  transform: scaleX(-1);

  .ComputerScreen {
    position: absolute;
    top: 0;
    left: 0; }

  .ComputerHandleScreen {
    position: absolute;
    top: 0;
    left: 0; }

  .ComputerAttack {
    animation-name: attack_animate;
    animation-iteration-count: 2;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-timing-function: linear; }

  .ComputerBlock {
    animation-name: attack_animate;
    animation-iteration-count: 2;
    animation-direction: alternate-reverse;
    animation-duration: 1s;
    animation-timing-function: linear; }

  .ComputeUnsuccessfulAttack {
    animation: attack_animate 1s linear alternate-reverse 0s,unsuccessful_attack_animate 1s linear 1s, attack_animate 1s linear alternate 2s; } }

.ComputerAttackForContainer {
  right: 500px; }

@keyframes unsuccessful_attack_animate {
  from {
    top: -130px;
    left: 18px;
    transform: rotate(-90deg);
    animation-timing-function: ease-in; }
  25% {
    top: -110px;
    left: 30px;
    transform: rotate(-75deg); }
  to {
    top: -130px;
    left: 18px;
    transform: rotate(-90deg);
    animation-timing-function: ease-in; } }

@keyframes attack_animate {
  from {
    top: -130px;
    left: 18px;
    transform: rotate(-90deg);
    animation-timing-function: ease-in; }
  25% {
    top: -110px;
    left: 30px;
    transform: rotate(-75deg); }
  50% {
    top: -70px;
    left: 35px;
    transform: rotate(-50deg); }

  75% {
    top: -30px;
    left: 25px;
    transform: rotate(-25deg); }
  to {
    top: 0;
    left: 0;
    transform: rotate(0deg); } }
