.ContainerModalWindow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 500000;

  .InsideContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: max-content;
    height: max-content;
    position: relative;
    background: #e5e5e5;
    border: 4px solid black;
    border-radius: 10px;
    padding: 20px;

    .closeButtonModal {
      position: absolute;
      right: 5px;
      top: 5px;
      background: red;
      width: 30px;
      height: 30px;
      outline: none; }

    .submitButtonModal {
      display: inline-block;
      font-weight: 700;
      color: rgb(209,209,217);
      text-decoration: none;
      text-shadow: 0 -1px 2px rgba(0,0,0,.2);
      padding: 15px;
      outline: none;
      border-radius: 3px;
      background: linear-gradient(rgb(110,112,120), rgb(81,81,86)) rgb(110,112,120);
      box-shadow: 0 1px rgba(255,255,255,.2) inset, 0 3px 5px rgba(0,1,6,.5), 0 0 1px 1px rgba(0,1,6,.2);
      transition: .2s ease-in-out;
      border: none;
      margin: 10px;

      &:hover {
        background: linear-gradient(rgb(126,126,134), rgb(70,71,76)) rgb(126,126,134); }

      &:active {
        background: linear-gradient(rgb(76,77,82), rgb(56,57,62)) rgb(76,77,82);
        box-shadow: 0 0 1px rgba(0,0,0,.5) inset, 0 2px 3px rgba(0,0,0,.5) inset, 0 1px 1px rgba(255,255,255,.1); } }

    .textAreaModal {
      margin-top: 30px;
      width: 50vw;
      height: 50vh;
      resize: none;
      outline: none; } } }

.hidden {
  display: none; }
