.waiting {
  font-size: 50px;
  text-align: center;
  margin-top: 20%;
  width: 100%;
  height: 100%; }

.MainComponentMainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ButtonsContainer {
    height: max-content;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border-radius: 15px;

    .ButtonsContainerLocalModel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }

    .Button {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
      color: rgba(255,255,255,.6);
      text-shadow: 1px 1px rgba(0,0,0,.3);
      text-decoration: none;
      padding: 20px;
      border-radius: 15px;

      box-shadow: inset 0 0 3px 1px rgba(0,0,0,.8), inset rgba(0,0,0,.3) -5px -5px 8px 5px, inset rgba(255,255,255,.5) 5px 5px 8px 5px, 1px 1px 1px rgba(255,255,255,.1), -2px -2px 5px rgba(0,0,0,.5);
      transition: .2s;
      border: none;
      outline: none;
      margin: 10px;

      &:hover {
        color: rgba(255,255,255,.9); }

      &:active {
        box-shadow: inset 0 0 5px 3px rgba(0,0,0,.8), inset rgba(0,0,0,.3) -5px -5px 8px 5px, inset rgba(255,255,255,.5) 5px 5px 8px 5px, 1px 1px 1px rgba(255,255,255,.2), -2px -2px 5px rgba(0,0,0,.5); } }

    .greenButton {
      background: rgb(10,120,10);

      &:hover {
        background: rgb(20,130,20); }

      &:active {
        background: rgb(0,110,0); } }

    .redButton {
      background: rgb(120, 13, 6);

      &:hover {
        background: rgb(147, 13, 6); }

      &:active {
        ackground: rgb(104, 12, 6); } }

    .yellowButton {
      background: rgb(204, 173, 27);

      &:hover {
        background: rgb(222, 191, 27); }

      &:active {
        background: rgb(179, 148, 27); } } } }
